<template>
  <div>
    <a-descriptions :column="3">
      <a-descriptions-item label="状态">
        {{ productUsageOrder.status }}
      </a-descriptions-item>

      <a-descriptions-item label="数据更新时间">
        {{ productUsageOrder.updated_at }}
      </a-descriptions-item>
    </a-descriptions>

    <a-row>
      <a-col :span="8">
        <a-descriptions :column="1">
          <a-descriptions-item label="流量池名称">
            {{ productUsageOrder.agents_product_name }}
          </a-descriptions-item>

          <a-descriptions-item label="卡号总数">
            {{ productUsageOrder.total_card_count | formatBigNumber }}
          </a-descriptions-item>

          <a-descriptions-item label="活跃卡号总数">
            {{ productUsageOrder.activated_card_count | formatBigNumber }}
          </a-descriptions-item>

          <a-descriptions-item label="非活跃卡号总数">
            {{ productUsageOrder.inactivated_card_count | formatBigNumber }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="8">
        <a-descriptions :column="1">
          <a-descriptions-item label="流量池额定流量">
            {{ productUsageOrder.capacity }}
          </a-descriptions-item>

          <a-descriptions-item label="已用流量">
            {{ productUsageOrder.used }}
          </a-descriptions-item>

          <a-descriptions-item label="超额用量">
            {{ productUsageOrder.exceed_usage }}
          </a-descriptions-item>

        </a-descriptions>
      </a-col>
      <a-col :span="8">
        <a-descriptions :column="1">
          <a-descriptions-item label="超额资费">
            {{ productUsageOrder.excess_price }}
          </a-descriptions-item>

          <a-descriptions-item label="超额费用(元)">
            {{ productUsageOrder.exceed_fee | formatCurrency }}
          </a-descriptions-item>

          <a-descriptions-item label="总费用(元)">
            {{ productUsageOrder.total_fee | formatCurrency }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>

    <search-sim-card-order @submit="submitSearch" />

    <div class="iotplt-table-operation-buttons">
      <a-button type="primary" @click="exportData">导出</a-button>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      row-key="id"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import { findSimCardOrders, exportSimCardOrders } from '@/api/agent_product_usage_order'
import SearchSimCardOrder from '@/views/agent_monthly_bills/card_use/top_agent_self/show/Search'
import Pagination from '@/components/Pagination'
import { exportExcel } from '@/api/excel'

export default {
  name: 'ShowAgentMonthlyBillTrafficShareOrder',
  components: {
    Pagination,
    SearchSimCardOrder
  },
  props: {
    productUsageOrder: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          title: 'ICCID/SIMID',
          dataIndex: 'iccid',
          fixed: 'left',
          width: 210,
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false,
          scopedSlots: { customRender: 'iccid' }
        },
        {
          title: '手机号码',
          width: 150,
          dataIndex: 'phone_number'
        },
        {
          title: '套餐名称',
          width: 250,
          dataIndex: 'agents_product_name'
        },
        {
          title: '套餐容量',
          dataIndex: 'package_capacity',
          sorter: true,
          width: 150,
          sortOrder: this.sort.sort_field === 'package_capacity' ? this.sort.sort_order : false
        },
        {
          title: '已用流量',
          dataIndex: 'used',
          sorter: true,
          width: 150,
          sortOrder: this.sort.sort_field === 'used' ? this.sort.sort_order : false
        },
        {
          title: '周期语音时长',
          dataIndex: 'voice_capacity'
        },
        {
          title: '周期已用时长',
          dataIndex: 'voice_usage',
          sorter: true,
          sortOrder: this.sort.sort_field === 'voice_usage' ? this.sort.sort_order : false
        }
      ]
    },
    productUsageOrderId() {
      return parseInt(this.$route.params.card_use_id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findSimCardOrders(this.productUsageOrderId, Object.assign({},
        this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    exportData() {
      exportSimCardOrders(this.productUsageOrderId, this.query).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
